import React from 'react'
import { Button } from 'react-bootstrap'

const LoginButton = () => {
  return (
    <Button
      href={`${process.env.REACT_APP_FURTKA_LOGIN_URL}?client_id=${process.env.REACT_APP_FURTKA_CLIENT_ID}`}
    >
      Zaloguj z Furtką
    </Button>
  )
}

export default LoginButton
