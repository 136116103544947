import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss'
import { Container } from 'react-bootstrap'
import { CookiesProvider } from 'react-cookie'
import { Route, Routes } from 'react-router-dom'
import Layout from './layout/Layout'
import Login from './pages/Login'
import { Provider as UrqlProvider } from 'urql'
import { getClient } from './lib/client'
import { AuthContext, useAuth } from './provider/auth'
import { useMemo } from 'react'
import AuthCallback from './pages/AuthCallback'
import Stores from './pages/Stores'
import Store from './pages/Store'
import Accounts from './pages/Accounts'
import Account from './pages/Account'
import Reports from './pages/Reports'
import Executions from './pages/Executions'

const App = () => {
  const auth = useAuth()
  const client = useMemo(() => getClient(auth), [auth])

  return (
    <CookiesProvider>
      <AuthContext.Provider value={auth}>
        <UrqlProvider value={client}>
          <Layout>
            <Container>
              <Routes>
                {!auth.isLogged && (
                  <>
                    <Route path="/" element={<Login />} />
                    <Route path="/auth/callback" element={<AuthCallback />} />
                  </>
                )}
                {auth.isLogged && (
                  <>
                    <Route path="/" element={<Stores />} />
                    <Route path="/store" element={<Store />} />
                    <Route path="/store/:id" element={<Store />} />
                    <Route path="/accounts" element={<Accounts />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/account/:id" element={<Account />} />
                    <Route path="/reports" element={<Reports />} />
                    <Route path="/executions" element={<Executions />} />
                  </>
                )}
              </Routes>
            </Container>
          </Layout>
        </UrqlProvider>
      </AuthContext.Provider>
    </CookiesProvider>
  )
}

export default App
