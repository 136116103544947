import Meta from '../components/Meta'
import { FC, useEffect, useState } from 'react'
import { useClient } from 'urql'
import { useParams } from 'react-router'
import { Controller, useForm } from 'react-hook-form'
import {
  GetAccountDocument,
  GetAccountQuery,
  GetAccountQueryVariables,
  GetStoresQuery,
  useGetStoresQuery,
  useInsertAccountMutation,
  useUpdateAccountMutation,
} from '../generated/urql.user'
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  FloatingLabel,
  Form,
  ListGroup,
  ListGroupItem,
  Row,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import { ChevronLeft, Plus, X } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'

type FormAccount = {
  email: string
  password: string
  storeIds: string[]
}

const Account: FC = () => {
  const pageTitle = 'Sklep'
  const { id } = useParams()
  const client = useClient()
  const navigate = useNavigate()
  const [, setGetAccountFetching] = useState(false)
  const [{ fetching: updateAccountFetching }, updateAccount] =
    useUpdateAccountMutation()
  const [{ fetching: insertAccountFetching }, insertAccount] =
    useInsertAccountMutation()
  const [{ data: storesData, fetching: storesFetching }] = useGetStoresQuery({
    variables: { limit: 0xfffff },
  })
  const [selectedStoreId, setSelectedStoreId] = useState<string>()
  const [generalError, setGeneralError] = useState<string>()
  const [successToast, setSuccessToast] = useState(false)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    register,
  } = useForm<FormAccount>({
    defaultValues: {
      email: '',
      password: '',
      storeIds: [],
    },
  })

  useEffect(() => {
    if (id) {
      setGetAccountFetching(true)
      client
        .query<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, {
          id,
        })
        .toPromise()
        .then(({ data }) => {
          if (data?.accountsByPk) {
            reset({
              email: data.accountsByPk.email || '',
              password: data.accountsByPk.password || '',
              storeIds: data.accountsByPk.accountStores.map(
                ({ storeId }) => storeId
              ),
            })
          }
        })
        .finally(() => setGetAccountFetching(false))
    }
  }, [id, client, reset])

  async function doSaveAccount({ storeIds, ...account }: FormAccount) {
    setGeneralError(undefined)

    const { error, data } = id
      ? await updateAccount({
          ...account,
          accountStores: storeIds.map((storeId) => ({
            accountId: id,
            storeId,
          })),
          id,
        })
      : await insertAccount({
          ...account,
          accountStores: storeIds.map((storeId) => ({ storeId })),
        })

    if (error) {
      setGeneralError(error.message)
      return
    }

    setSuccessToast(true)

    if (data && 'insertAccountsOne' in data) {
      navigate(`/account/${data.insertAccountsOne?.id}`)
    }
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-start">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/accounts')}
          >
            <ChevronLeft size={20} />
            &nbsp;Wróć
          </Button>
        </ButtonGroup>
      </div>
      <Form
        onSubmit={handleSubmit(doSaveAccount, console.log)}
        className="mt-4"
      >
        {generalError && <Alert variant="danger">{generalError}</Alert>}

        <ToastContainer
          position="bottom-center"
          className="mb-4"
          style={{ zIndex: 10 }}
        >
          <Toast
            onClose={() => setSuccessToast(false)}
            show={successToast}
            autohide
            animation={true}
            bg="success"
          >
            <Toast.Header>
              <strong className="me-auto">Sukces</strong>
            </Toast.Header>
            <Toast.Body>Pomyślnie zapisano parametry konta</Toast.Body>
          </Toast>
        </ToastContainer>

        <Row>
          <Col>
            <h4>Dane konta</h4>
            <FloatingLabel label="Email" className="mb-3">
              <Form.Control
                isInvalid={!!errors.email}
                type="text"
                placeholder="Email"
                {...register('email', {
                  required: 'Proszę podać e-mail',
                })}
              />
              {errors.email && (
                <Form.Control.Feedback>
                  {errors.email.message}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
            <FloatingLabel label="Hasło" className="mb-3">
              <Form.Control
                isInvalid={!!errors.password}
                type="text"
                placeholder="Hasło"
                {...register('password', {
                  required: 'Proszę podać hasło',
                })}
              />
              {errors.password && (
                <Form.Control.Feedback>
                  {errors.password.message}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          </Col>
          <Col>
            <h4>Sklepy</h4>
            <Controller
              name="storeIds"
              control={control}
              render={({ field: { value: storeIds, onChange } }) => {
                const stores = (storesData?.stores || []).filter(
                  ({ id }) => !storeIds.includes(id)
                )

                const selectedStores = storeIds
                  .map((storeId) =>
                    (storesData?.stores || []).find(({ id }) => id === storeId)
                  )
                  .filter(Boolean) as GetStoresQuery['stores']

                return (
                  <>
                    <ListGroup className={selectedStores.length ? 'mb-3' : ''}>
                      {selectedStores.map((store) => (
                        <ListGroupItem
                          key={store.id}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div>{store.name}</div>
                          <Button
                            variant="outline-danger"
                            className="d-flex align-items-center p-0"
                            onClick={() =>
                              onChange(
                                storeIds.filter(
                                  (storeId) => storeId !== store.id
                                )
                              )
                            }
                          >
                            <X size={20} />
                          </Button>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                    <div className="d-flex">
                      <Form.Select
                        disabled={!stores.length || storesFetching}
                        value={selectedStoreId}
                        onChange={({ target: { value } }) =>
                          setSelectedStoreId(value || undefined)
                        }
                      >
                        <option>Proszę wybrać sklep</option>
                        {stores.map((store) => (
                          <option value={store.id} key={store.id}>
                            {store.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Button
                        className="ms-3 d-flex align-items-center"
                        disabled={
                          !stores.length || storesFetching || !selectedStoreId
                        }
                        onClick={() => onChange([...storeIds, selectedStoreId])}
                      >
                        <Plus size={20} />
                        &nbsp;Dodaj
                      </Button>
                    </div>
                  </>
                )
              }}
            />
          </Col>
        </Row>
        <Button
          variant="primary"
          type="submit"
          disabled={updateAccountFetching || insertAccountFetching}
        >
          Zapisz
        </Button>
      </Form>
    </div>
  )
}

export default Account
