import { Nav, Container, Navbar, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { BoxArrowRight } from 'react-bootstrap-icons'
import { AuthContext } from '../provider/auth'
import { useContext } from 'react'
import { useLogout } from '../hooks/logout'

const Menu = () => {
  const auth = useContext(AuthContext)
  const logout = useLogout()

  return (
    <Navbar bg="dark" expand="lg" variant="dark">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>Products Crawler</Navbar.Brand>
        </LinkContainer>
        <>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {!auth.isLogged && (
                <LinkContainer to="/">
                  <Nav.Link>Logowanie</Nav.Link>
                </LinkContainer>
              )}
              {auth.isLogged && (
                <>
                  <LinkContainer to="/">
                    <Nav.Link>Sklepy</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/accounts">
                    <Nav.Link>Konta</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/reports">
                    <Nav.Link>Raporty</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/executions">
                    <Nav.Link>Historia wykonań</Nav.Link>
                  </LinkContainer>
                </>
              )}
            </Nav>
            {auth.isLogged && (
              <Nav>
                <NavDropdown
                  align="end"
                  title={
                    <div className="avatar-circle-xs">
                      <span className="initials">{auth.initials}</span>
                    </div>
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.ItemText>
                    <span className="text-nowrap">
                      Zalogowany jako: <strong>{auth.name}</strong>
                    </span>
                  </NavDropdown.ItemText>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={logout}>
                    Wyloguj <BoxArrowRight size={20} />
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            )}{' '}
          </Navbar.Collapse>
        </>
      </Container>
    </Navbar>
  )
}

export default Menu
