import Meta from '../components/Meta'
import { FC, useContext, useEffect, useState } from 'react'
import { useLoginMutation } from '../generated/urql.anonymous'
import { Alert } from 'react-bootstrap'
import { AuthContext } from '../provider/auth'
import { useNavigate } from 'react-router'

const AuthCallback: FC = () => {
  // page content
  const pageTitle = 'Logowanie...'
  const [, login] = useLoginMutation()
  const [generalError, setGeneralError] = useState<string>()
  const { setAccessToken } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    const furtkaToken = new URLSearchParams(window.location.search).get('token')
    if (furtkaToken) {
      login({ furtkaToken }).then(({ data, error }) => {
        if (error) {
          switch (error.graphQLErrors[0].extensions.code) {
            case 'invalid-credentials':
              setGeneralError('Nieprawidłowy email lub hasło')
              return
            default:
              setGeneralError(error.message)
          }
          return
        }

        if (data?.login?.accessToken) {
          setAccessToken(data?.login?.accessToken)
          navigate('/')
        }
      })
    }
  }, [login, navigate, setAccessToken])

  return (
    <div>
      <Meta title={pageTitle} />
      <h2>Logowanie...</h2>
      {generalError && <Alert variant="danger">{generalError}</Alert>}
    </div>
  )
}

export default AuthCallback
