import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  numeric: any;
  timestamptz: any;
  uuid: any;
};

/** columns and relationships of "accountStores" */
export type AccountStores = {
  __typename?: 'AccountStores';
  /** An object relationship */
  account?: Maybe<Accounts>;
  accountId: Scalars['uuid'];
  /** An object relationship */
  store?: Maybe<Stores>;
  storeId: Scalars['uuid'];
};

/** aggregated selection of "accountStores" */
export type AccountStoresAggregate = {
  __typename?: 'AccountStoresAggregate';
  aggregate?: Maybe<AccountStoresAggregateFields>;
  nodes: Array<AccountStores>;
};

/** aggregate fields of "accountStores" */
export type AccountStoresAggregateFields = {
  __typename?: 'AccountStoresAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<AccountStoresMaxFields>;
  min?: Maybe<AccountStoresMinFields>;
};


/** aggregate fields of "accountStores" */
export type AccountStoresAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AccountStoresSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "accountStores" */
export type AccountStoresAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AccountStores_Max_Order_By>;
  min?: InputMaybe<AccountStores_Min_Order_By>;
};

/** input type for inserting array relation for remote table "accountStores" */
export type AccountStoresArrRelInsertInput = {
  data: Array<AccountStoresInsertInput>;
  /** upsert condition */
  onConflict?: InputMaybe<AccountStoresOnConflict>;
};

/** Boolean expression to filter rows from the table "accountStores". All fields are combined with a logical 'AND'. */
export type AccountStoresBoolExp = {
  _and?: InputMaybe<Array<AccountStoresBoolExp>>;
  _not?: InputMaybe<AccountStoresBoolExp>;
  _or?: InputMaybe<Array<AccountStoresBoolExp>>;
  account?: InputMaybe<AccountsBoolExp>;
  accountId?: InputMaybe<UuidComparisonExp>;
  store?: InputMaybe<StoresBoolExp>;
  storeId?: InputMaybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "accountStores" */
export enum AccountStoresConstraint {
  /** unique or primary key constraint on columns "accountId", "storeId" */
  AccountStoresPkey = 'accountStores_pkey'
}

/** input type for inserting data into table "accountStores" */
export type AccountStoresInsertInput = {
  account?: InputMaybe<AccountsObjRelInsertInput>;
  accountId?: InputMaybe<Scalars['uuid']>;
  store?: InputMaybe<StoresObjRelInsertInput>;
  storeId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type AccountStoresMaxFields = {
  __typename?: 'AccountStoresMaxFields';
  accountId?: Maybe<Scalars['uuid']>;
  storeId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type AccountStoresMinFields = {
  __typename?: 'AccountStoresMinFields';
  accountId?: Maybe<Scalars['uuid']>;
  storeId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "accountStores" */
export type AccountStoresMutationResponse = {
  __typename?: 'AccountStoresMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountStores>;
};

/** on_conflict condition type for table "accountStores" */
export type AccountStoresOnConflict = {
  constraint: AccountStoresConstraint;
  update_columns?: Array<AccountStoresUpdateColumn>;
  where?: InputMaybe<AccountStoresBoolExp>;
};

/** Ordering options when selecting data from "accountStores". */
export type AccountStoresOrderBy = {
  account?: InputMaybe<AccountsOrderBy>;
  accountId?: InputMaybe<OrderBy>;
  store?: InputMaybe<StoresOrderBy>;
  storeId?: InputMaybe<OrderBy>;
};

/** select columns of table "accountStores" */
export enum AccountStoresSelectColumn {
  /** column name */
  AccountId = 'accountId',
  /** column name */
  StoreId = 'storeId'
}

/** Streaming cursor of the table "accountStores" */
export type AccountStoresStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccountStoresStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccountStoresStreamCursorValueInput = {
  accountId?: InputMaybe<Scalars['uuid']>;
  storeId?: InputMaybe<Scalars['uuid']>;
};

/** placeholder for update columns of table "accountStores" (current role has no relevant permissions) */
export enum AccountStoresUpdateColumn {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER'
}

/** columns and relationships of "accounts" */
export type Accounts = {
  __typename?: 'Accounts';
  /** An array relationship */
  accountStores: Array<AccountStores>;
  /** An aggregate relationship */
  accountStoresAggregate: AccountStoresAggregate;
  email: Scalars['String'];
  id: Scalars['uuid'];
  password: Scalars['String'];
};


/** columns and relationships of "accounts" */
export type AccountsAccountStoresArgs = {
  distinctOn?: InputMaybe<Array<AccountStoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountStoresOrderBy>>;
  where?: InputMaybe<AccountStoresBoolExp>;
};


/** columns and relationships of "accounts" */
export type AccountsAccountStoresAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccountStoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountStoresOrderBy>>;
  where?: InputMaybe<AccountStoresBoolExp>;
};

/** aggregated selection of "accounts" */
export type AccountsAggregate = {
  __typename?: 'AccountsAggregate';
  aggregate?: Maybe<AccountsAggregateFields>;
  nodes: Array<Accounts>;
};

/** aggregate fields of "accounts" */
export type AccountsAggregateFields = {
  __typename?: 'AccountsAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<AccountsMaxFields>;
  min?: Maybe<AccountsMinFields>;
};


/** aggregate fields of "accounts" */
export type AccountsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AccountsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "accounts". All fields are combined with a logical 'AND'. */
export type AccountsBoolExp = {
  _and?: InputMaybe<Array<AccountsBoolExp>>;
  _not?: InputMaybe<AccountsBoolExp>;
  _or?: InputMaybe<Array<AccountsBoolExp>>;
  accountStores?: InputMaybe<AccountStoresBoolExp>;
  accountStores_aggregate?: InputMaybe<AccountStores_Aggregate_Bool_Exp>;
  email?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  password?: InputMaybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "accounts" */
export enum AccountsConstraint {
  /** unique or primary key constraint on columns "id" */
  AccountsPkey = 'accounts_pkey'
}

/** input type for inserting data into table "accounts" */
export type AccountsInsertInput = {
  accountStores?: InputMaybe<AccountStoresArrRelInsertInput>;
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AccountsMaxFields = {
  __typename?: 'AccountsMaxFields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  password?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AccountsMinFields = {
  __typename?: 'AccountsMinFields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  password?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "accounts" */
export type AccountsMutationResponse = {
  __typename?: 'AccountsMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounts>;
};

/** input type for inserting object relation for remote table "accounts" */
export type AccountsObjRelInsertInput = {
  data: AccountsInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<AccountsOnConflict>;
};

/** on_conflict condition type for table "accounts" */
export type AccountsOnConflict = {
  constraint: AccountsConstraint;
  update_columns?: Array<AccountsUpdateColumn>;
  where?: InputMaybe<AccountsBoolExp>;
};

/** Ordering options when selecting data from "accounts". */
export type AccountsOrderBy = {
  accountStoresAggregate?: InputMaybe<AccountStoresAggregateOrderBy>;
  email?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  password?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: accounts */
export type AccountsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "accounts" */
export enum AccountsSelectColumn {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Password = 'password'
}

/** input type for updating data in table "accounts" */
export type AccountsSetInput = {
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "accounts" */
export type AccountsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: AccountsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccountsStreamCursorValueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  password?: InputMaybe<Scalars['String']>;
};

/** update columns of table "accounts" */
export enum AccountsUpdateColumn {
  /** column name */
  Email = 'email',
  /** column name */
  Password = 'password'
}

export type AccountsUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccountsSetInput>;
  /** filter the rows which have to be updated */
  where: AccountsBoolExp;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

export type ExecuteOutput = {
  __typename?: 'ExecuteOutput';
  result: Scalars['String'];
};

/** columns and relationships of "executions" */
export type Executions = {
  __typename?: 'Executions';
  date: Scalars['timestamptz'];
  duration?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  store?: Maybe<Stores>;
  storeId: Scalars['uuid'];
};

/** aggregated selection of "executions" */
export type ExecutionsAggregate = {
  __typename?: 'ExecutionsAggregate';
  aggregate?: Maybe<ExecutionsAggregateFields>;
  nodes: Array<Executions>;
};

/** aggregate fields of "executions" */
export type ExecutionsAggregateFields = {
  __typename?: 'ExecutionsAggregateFields';
  avg?: Maybe<ExecutionsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ExecutionsMaxFields>;
  min?: Maybe<ExecutionsMinFields>;
  stddev?: Maybe<ExecutionsStddevFields>;
  stddevPop?: Maybe<ExecutionsStddev_PopFields>;
  stddevSamp?: Maybe<ExecutionsStddev_SampFields>;
  sum?: Maybe<ExecutionsSumFields>;
  varPop?: Maybe<ExecutionsVar_PopFields>;
  varSamp?: Maybe<ExecutionsVar_SampFields>;
  variance?: Maybe<ExecutionsVarianceFields>;
};


/** aggregate fields of "executions" */
export type ExecutionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ExecutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "executions" */
export type ExecutionsAggregateOrderBy = {
  avg?: InputMaybe<Executions_Avg_Order_By>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<Executions_Max_Order_By>;
  min?: InputMaybe<Executions_Min_Order_By>;
  stddev?: InputMaybe<Executions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Executions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Executions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Executions_Sum_Order_By>;
  var_pop?: InputMaybe<Executions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Executions_Var_Samp_Order_By>;
  variance?: InputMaybe<Executions_Variance_Order_By>;
};

/** aggregate avg on columns */
export type ExecutionsAvgFields = {
  __typename?: 'ExecutionsAvgFields';
  duration?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "executions". All fields are combined with a logical 'AND'. */
export type ExecutionsBoolExp = {
  _and?: InputMaybe<Array<ExecutionsBoolExp>>;
  _not?: InputMaybe<ExecutionsBoolExp>;
  _or?: InputMaybe<Array<ExecutionsBoolExp>>;
  date?: InputMaybe<TimestamptzComparisonExp>;
  duration?: InputMaybe<IntComparisonExp>;
  error?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  store?: InputMaybe<StoresBoolExp>;
  storeId?: InputMaybe<UuidComparisonExp>;
};

/** aggregate max on columns */
export type ExecutionsMaxFields = {
  __typename?: 'ExecutionsMaxFields';
  date?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  storeId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type ExecutionsMinFields = {
  __typename?: 'ExecutionsMinFields';
  date?: Maybe<Scalars['timestamptz']>;
  duration?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  storeId?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "executions". */
export type ExecutionsOrderBy = {
  date?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  error?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  store?: InputMaybe<StoresOrderBy>;
  storeId?: InputMaybe<OrderBy>;
};

/** select columns of table "executions" */
export enum ExecutionsSelectColumn {
  /** column name */
  Date = 'date',
  /** column name */
  Duration = 'duration',
  /** column name */
  Error = 'error',
  /** column name */
  Id = 'id',
  /** column name */
  StoreId = 'storeId'
}

/** aggregate stddev on columns */
export type ExecutionsStddevFields = {
  __typename?: 'ExecutionsStddevFields';
  duration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type ExecutionsStddev_PopFields = {
  __typename?: 'ExecutionsStddev_popFields';
  duration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type ExecutionsStddev_SampFields = {
  __typename?: 'ExecutionsStddev_sampFields';
  duration?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "executions" */
export type ExecutionsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ExecutionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ExecutionsStreamCursorValueInput = {
  date?: InputMaybe<Scalars['timestamptz']>;
  duration?: InputMaybe<Scalars['Int']>;
  error?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  storeId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type ExecutionsSumFields = {
  __typename?: 'ExecutionsSumFields';
  duration?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type ExecutionsVar_PopFields = {
  __typename?: 'ExecutionsVar_popFields';
  duration?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type ExecutionsVar_SampFields = {
  __typename?: 'ExecutionsVar_sampFields';
  duration?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type ExecutionsVarianceFields = {
  __typename?: 'ExecutionsVarianceFields';
  duration?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type LogoutOutput = {
  __typename?: 'LogoutOutput';
  result: Scalars['String'];
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'ASC',
  /** in ascending order, nulls first */
  AscNullsFirst = 'ASC_NULLS_FIRST',
  /** in ascending order, nulls last */
  AscNullsLast = 'ASC_NULLS_LAST',
  /** in descending order, nulls first */
  Desc = 'DESC',
  /** in descending order, nulls first */
  DescNullsFirst = 'DESC_NULLS_FIRST',
  /** in descending order, nulls last */
  DescNullsLast = 'DESC_NULLS_LAST'
}

/** columns and relationships of "products" */
export type Products = {
  __typename?: 'Products';
  basisWeight?: Maybe<Scalars['numeric']>;
  brand?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  discountPrice?: Maybe<Scalars['numeric']>;
  discountPricePerKg?: Maybe<Scalars['numeric']>;
  externalId: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  price: Scalars['numeric'];
  pricePerKg?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  store?: Maybe<Stores>;
  storeId: Scalars['uuid'];
  subCategory?: Maybe<Scalars['String']>;
  subSubCategory?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};

/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
export type ProductsBoolExp = {
  _and?: InputMaybe<Array<ProductsBoolExp>>;
  _not?: InputMaybe<ProductsBoolExp>;
  _or?: InputMaybe<Array<ProductsBoolExp>>;
  basisWeight?: InputMaybe<NumericComparisonExp>;
  brand?: InputMaybe<StringComparisonExp>;
  category?: InputMaybe<StringComparisonExp>;
  createdAt?: InputMaybe<TimestamptzComparisonExp>;
  description?: InputMaybe<StringComparisonExp>;
  discountPrice?: InputMaybe<NumericComparisonExp>;
  discountPricePerKg?: InputMaybe<NumericComparisonExp>;
  externalId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  price?: InputMaybe<NumericComparisonExp>;
  pricePerKg?: InputMaybe<NumericComparisonExp>;
  store?: InputMaybe<StoresBoolExp>;
  storeId?: InputMaybe<UuidComparisonExp>;
  subCategory?: InputMaybe<StringComparisonExp>;
  subSubCategory?: InputMaybe<StringComparisonExp>;
  updatedAt?: InputMaybe<TimestamptzComparisonExp>;
};

/** Ordering options when selecting data from "products". */
export type ProductsOrderBy = {
  basisWeight?: InputMaybe<OrderBy>;
  brand?: InputMaybe<OrderBy>;
  category?: InputMaybe<OrderBy>;
  createdAt?: InputMaybe<OrderBy>;
  description?: InputMaybe<OrderBy>;
  discountPrice?: InputMaybe<OrderBy>;
  discountPricePerKg?: InputMaybe<OrderBy>;
  externalId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  price?: InputMaybe<OrderBy>;
  pricePerKg?: InputMaybe<OrderBy>;
  store?: InputMaybe<StoresOrderBy>;
  storeId?: InputMaybe<OrderBy>;
  subCategory?: InputMaybe<OrderBy>;
  subSubCategory?: InputMaybe<OrderBy>;
  updatedAt?: InputMaybe<OrderBy>;
};

/** select columns of table "products" */
export enum ProductsSelectColumn {
  /** column name */
  BasisWeight = 'basisWeight',
  /** column name */
  Brand = 'brand',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  DiscountPrice = 'discountPrice',
  /** column name */
  DiscountPricePerKg = 'discountPricePerKg',
  /** column name */
  ExternalId = 'externalId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  PricePerKg = 'pricePerKg',
  /** column name */
  StoreId = 'storeId',
  /** column name */
  SubCategory = 'subCategory',
  /** column name */
  SubSubCategory = 'subSubCategory',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** Streaming cursor of the table "products" */
export type ProductsStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: ProductsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ProductsStreamCursorValueInput = {
  basisWeight?: InputMaybe<Scalars['numeric']>;
  brand?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  discountPrice?: InputMaybe<Scalars['numeric']>;
  discountPricePerKg?: InputMaybe<Scalars['numeric']>;
  externalId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  pricePerKg?: InputMaybe<Scalars['numeric']>;
  storeId?: InputMaybe<Scalars['uuid']>;
  subCategory?: InputMaybe<Scalars['String']>;
  subSubCategory?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "sources" */
export type Sources = {
  __typename?: 'Sources';
  source: Scalars['String'];
};

/** aggregated selection of "sources" */
export type SourcesAggregate = {
  __typename?: 'SourcesAggregate';
  aggregate?: Maybe<SourcesAggregateFields>;
  nodes: Array<Sources>;
};

/** aggregate fields of "sources" */
export type SourcesAggregateFields = {
  __typename?: 'SourcesAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<SourcesMaxFields>;
  min?: Maybe<SourcesMinFields>;
};


/** aggregate fields of "sources" */
export type SourcesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<SourcesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "sources". All fields are combined with a logical 'AND'. */
export type SourcesBoolExp = {
  _and?: InputMaybe<Array<SourcesBoolExp>>;
  _not?: InputMaybe<SourcesBoolExp>;
  _or?: InputMaybe<Array<SourcesBoolExp>>;
  source?: InputMaybe<StringComparisonExp>;
};

export enum SourcesEnum {
  Biedronka = 'BIEDRONKA',
  Everli = 'EVERLI',
  Glovo = 'GLOVO'
}

/** Boolean expression to compare columns of type "SourcesEnum". All fields are combined with logical 'AND'. */
export type SourcesEnumComparisonExp = {
  _eq?: InputMaybe<SourcesEnum>;
  _in?: InputMaybe<Array<SourcesEnum>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<SourcesEnum>;
  _nin?: InputMaybe<Array<SourcesEnum>>;
};

/** aggregate max on columns */
export type SourcesMaxFields = {
  __typename?: 'SourcesMaxFields';
  source?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type SourcesMinFields = {
  __typename?: 'SourcesMinFields';
  source?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "sources". */
export type SourcesOrderBy = {
  source?: InputMaybe<OrderBy>;
};

/** select columns of table "sources" */
export enum SourcesSelectColumn {
  /** column name */
  Source = 'source'
}

/** Streaming cursor of the table "sources" */
export type SourcesStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: SourcesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type SourcesStreamCursorValueInput = {
  source?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "stores" */
export type Stores = {
  __typename?: 'Stores';
  /** An array relationship */
  accountStores: Array<AccountStores>;
  /** An aggregate relationship */
  accountStoresAggregate: AccountStoresAggregate;
  enabled: Scalars['Boolean'];
  /** An array relationship */
  executions: Array<Executions>;
  /** An aggregate relationship */
  executionsAggregate: ExecutionsAggregate;
  externalLocationId: Scalars['String'];
  externalStoreId: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  schedule: Scalars['String'];
  source: SourcesEnum;
};


/** columns and relationships of "stores" */
export type StoresAccountStoresArgs = {
  distinctOn?: InputMaybe<Array<AccountStoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountStoresOrderBy>>;
  where?: InputMaybe<AccountStoresBoolExp>;
};


/** columns and relationships of "stores" */
export type StoresAccountStoresAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccountStoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountStoresOrderBy>>;
  where?: InputMaybe<AccountStoresBoolExp>;
};


/** columns and relationships of "stores" */
export type StoresExecutionsArgs = {
  distinctOn?: InputMaybe<Array<ExecutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExecutionsOrderBy>>;
  where?: InputMaybe<ExecutionsBoolExp>;
};


/** columns and relationships of "stores" */
export type StoresExecutionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExecutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExecutionsOrderBy>>;
  where?: InputMaybe<ExecutionsBoolExp>;
};

/** aggregated selection of "stores" */
export type StoresAggregate = {
  __typename?: 'StoresAggregate';
  aggregate?: Maybe<StoresAggregateFields>;
  nodes: Array<Stores>;
};

/** aggregate fields of "stores" */
export type StoresAggregateFields = {
  __typename?: 'StoresAggregateFields';
  count: Scalars['Int'];
  max?: Maybe<StoresMaxFields>;
  min?: Maybe<StoresMinFields>;
};


/** aggregate fields of "stores" */
export type StoresAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<StoresSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "stores". All fields are combined with a logical 'AND'. */
export type StoresBoolExp = {
  _and?: InputMaybe<Array<StoresBoolExp>>;
  _not?: InputMaybe<StoresBoolExp>;
  _or?: InputMaybe<Array<StoresBoolExp>>;
  accountStores?: InputMaybe<AccountStoresBoolExp>;
  accountStores_aggregate?: InputMaybe<AccountStores_Aggregate_Bool_Exp>;
  enabled?: InputMaybe<BooleanComparisonExp>;
  executions?: InputMaybe<ExecutionsBoolExp>;
  executions_aggregate?: InputMaybe<Executions_Aggregate_Bool_Exp>;
  externalLocationId?: InputMaybe<StringComparisonExp>;
  externalStoreId?: InputMaybe<StringComparisonExp>;
  id?: InputMaybe<UuidComparisonExp>;
  name?: InputMaybe<StringComparisonExp>;
  schedule?: InputMaybe<StringComparisonExp>;
  source?: InputMaybe<SourcesEnumComparisonExp>;
};

/** unique or primary key constraints on table "stores" */
export enum StoresConstraint {
  /** unique or primary key constraint on columns "id" */
  StoresPkey = 'stores_pkey'
}

/** input type for inserting data into table "stores" */
export type StoresInsertInput = {
  accountStores?: InputMaybe<AccountStoresArrRelInsertInput>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  externalLocationId?: InputMaybe<Scalars['String']>;
  externalStoreId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<SourcesEnum>;
};

/** aggregate max on columns */
export type StoresMaxFields = {
  __typename?: 'StoresMaxFields';
  externalLocationId?: Maybe<Scalars['String']>;
  externalStoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type StoresMinFields = {
  __typename?: 'StoresMinFields';
  externalLocationId?: Maybe<Scalars['String']>;
  externalStoreId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "stores" */
export type StoresMutationResponse = {
  __typename?: 'StoresMutationResponse';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stores>;
};

/** input type for inserting object relation for remote table "stores" */
export type StoresObjRelInsertInput = {
  data: StoresInsertInput;
  /** upsert condition */
  onConflict?: InputMaybe<StoresOnConflict>;
};

/** on_conflict condition type for table "stores" */
export type StoresOnConflict = {
  constraint: StoresConstraint;
  update_columns?: Array<StoresUpdateColumn>;
  where?: InputMaybe<StoresBoolExp>;
};

/** Ordering options when selecting data from "stores". */
export type StoresOrderBy = {
  accountStoresAggregate?: InputMaybe<AccountStoresAggregateOrderBy>;
  enabled?: InputMaybe<OrderBy>;
  executionsAggregate?: InputMaybe<ExecutionsAggregateOrderBy>;
  externalLocationId?: InputMaybe<OrderBy>;
  externalStoreId?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  name?: InputMaybe<OrderBy>;
  schedule?: InputMaybe<OrderBy>;
  source?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: stores */
export type StoresPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "stores" */
export enum StoresSelectColumn {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  ExternalLocationId = 'externalLocationId',
  /** column name */
  ExternalStoreId = 'externalStoreId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  Source = 'source'
}

/** input type for updating data in table "stores" */
export type StoresSetInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  externalLocationId?: InputMaybe<Scalars['String']>;
  externalStoreId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<SourcesEnum>;
};

/** Streaming cursor of the table "stores" */
export type StoresStreamCursorInput = {
  /** Stream column input with initial value */
  initialValue: StoresStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type StoresStreamCursorValueInput = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  externalLocationId?: InputMaybe<Scalars['String']>;
  externalStoreId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<SourcesEnum>;
};

/** update columns of table "stores" */
export enum StoresUpdateColumn {
  /** column name */
  Enabled = 'enabled',
  /** column name */
  ExternalLocationId = 'externalLocationId',
  /** column name */
  ExternalStoreId = 'externalStoreId',
  /** column name */
  Name = 'name',
  /** column name */
  Schedule = 'schedule',
  /** column name */
  Source = 'source'
}

export type StoresUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<StoresSetInput>;
  /** filter the rows which have to be updated */
  where: StoresBoolExp;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _isNull?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type AccountStores_Aggregate_Bool_Exp = {
  count?: InputMaybe<AccountStores_Aggregate_Bool_Exp_Count>;
};

export type AccountStores_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AccountStoresSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<AccountStoresBoolExp>;
  predicate: IntComparisonExp;
};

/** order by max() on columns of table "accountStores" */
export type AccountStores_Max_Order_By = {
  accountId?: InputMaybe<OrderBy>;
  storeId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "accountStores" */
export type AccountStores_Min_Order_By = {
  accountId?: InputMaybe<OrderBy>;
  storeId?: InputMaybe<OrderBy>;
};

export type Executions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Executions_Aggregate_Bool_Exp_Count>;
};

export type Executions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<ExecutionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<ExecutionsBoolExp>;
  predicate: IntComparisonExp;
};

/** order by avg() on columns of table "executions" */
export type Executions_Avg_Order_By = {
  duration?: InputMaybe<OrderBy>;
};

/** order by max() on columns of table "executions" */
export type Executions_Max_Order_By = {
  date?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  error?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  storeId?: InputMaybe<OrderBy>;
};

/** order by min() on columns of table "executions" */
export type Executions_Min_Order_By = {
  date?: InputMaybe<OrderBy>;
  duration?: InputMaybe<OrderBy>;
  error?: InputMaybe<OrderBy>;
  id?: InputMaybe<OrderBy>;
  storeId?: InputMaybe<OrderBy>;
};

/** order by stddev() on columns of table "executions" */
export type Executions_Stddev_Order_By = {
  duration?: InputMaybe<OrderBy>;
};

/** order by stddev_pop() on columns of table "executions" */
export type Executions_Stddev_Pop_Order_By = {
  duration?: InputMaybe<OrderBy>;
};

/** order by stddev_samp() on columns of table "executions" */
export type Executions_Stddev_Samp_Order_By = {
  duration?: InputMaybe<OrderBy>;
};

/** order by sum() on columns of table "executions" */
export type Executions_Sum_Order_By = {
  duration?: InputMaybe<OrderBy>;
};

/** order by var_pop() on columns of table "executions" */
export type Executions_Var_Pop_Order_By = {
  duration?: InputMaybe<OrderBy>;
};

/** order by var_samp() on columns of table "executions" */
export type Executions_Var_Samp_Order_By = {
  duration?: InputMaybe<OrderBy>;
};

/** order by variance() on columns of table "executions" */
export type Executions_Variance_Order_By = {
  duration?: InputMaybe<OrderBy>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "accountStores" */
  deleteAccountStores?: Maybe<AccountStoresMutationResponse>;
  /** delete single row from the table: "accountStores" */
  deleteAccountStoresByPk?: Maybe<AccountStores>;
  /** delete data from the table: "accounts" */
  deleteAccounts?: Maybe<AccountsMutationResponse>;
  /** delete single row from the table: "accounts" */
  deleteAccountsByPk?: Maybe<Accounts>;
  execute?: Maybe<ExecuteOutput>;
  /** insert data into the table: "accountStores" */
  insertAccountStores?: Maybe<AccountStoresMutationResponse>;
  /** insert a single row into the table: "accountStores" */
  insertAccountStoresOne?: Maybe<AccountStores>;
  /** insert data into the table: "accounts" */
  insertAccounts?: Maybe<AccountsMutationResponse>;
  /** insert a single row into the table: "accounts" */
  insertAccountsOne?: Maybe<Accounts>;
  /** insert data into the table: "stores" */
  insertStores?: Maybe<StoresMutationResponse>;
  /** insert a single row into the table: "stores" */
  insertStoresOne?: Maybe<Stores>;
  logout?: Maybe<LogoutOutput>;
  /** update data of the table: "accounts" */
  updateAccounts?: Maybe<AccountsMutationResponse>;
  /** update single row of the table: "accounts" */
  updateAccountsByPk?: Maybe<Accounts>;
  /** update multiples rows of table: "accounts" */
  updateAccountsMany?: Maybe<Array<Maybe<AccountsMutationResponse>>>;
  /** update data of the table: "stores" */
  updateStores?: Maybe<StoresMutationResponse>;
  /** update single row of the table: "stores" */
  updateStoresByPk?: Maybe<Stores>;
  /** update multiples rows of table: "stores" */
  updateStoresMany?: Maybe<Array<Maybe<StoresMutationResponse>>>;
};


/** mutation root */
export type Mutation_RootDeleteAccountStoresArgs = {
  where: AccountStoresBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAccountStoresByPkArgs = {
  accountId: Scalars['uuid'];
  storeId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDeleteAccountsArgs = {
  where: AccountsBoolExp;
};


/** mutation root */
export type Mutation_RootDeleteAccountsByPkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootExecuteArgs = {
  storeId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsertAccountStoresArgs = {
  objects: Array<AccountStoresInsertInput>;
  onConflict?: InputMaybe<AccountStoresOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAccountStoresOneArgs = {
  object: AccountStoresInsertInput;
  onConflict?: InputMaybe<AccountStoresOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAccountsArgs = {
  objects: Array<AccountsInsertInput>;
  onConflict?: InputMaybe<AccountsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertAccountsOneArgs = {
  object: AccountsInsertInput;
  onConflict?: InputMaybe<AccountsOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertStoresArgs = {
  objects: Array<StoresInsertInput>;
  onConflict?: InputMaybe<StoresOnConflict>;
};


/** mutation root */
export type Mutation_RootInsertStoresOneArgs = {
  object: StoresInsertInput;
  onConflict?: InputMaybe<StoresOnConflict>;
};


/** mutation root */
export type Mutation_RootUpdateAccountsArgs = {
  _set?: InputMaybe<AccountsSetInput>;
  where: AccountsBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateAccountsByPkArgs = {
  _set?: InputMaybe<AccountsSetInput>;
  pk_columns: AccountsPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateAccountsManyArgs = {
  updates: Array<AccountsUpdates>;
};


/** mutation root */
export type Mutation_RootUpdateStoresArgs = {
  _set?: InputMaybe<StoresSetInput>;
  where: StoresBoolExp;
};


/** mutation root */
export type Mutation_RootUpdateStoresByPkArgs = {
  _set?: InputMaybe<StoresSetInput>;
  pk_columns: StoresPkColumnsInput;
};


/** mutation root */
export type Mutation_RootUpdateStoresManyArgs = {
  updates: Array<StoresUpdates>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** An array relationship */
  accountStores: Array<AccountStores>;
  /** An aggregate relationship */
  accountStoresAggregate: AccountStoresAggregate;
  /** fetch data from the table: "accountStores" using primary key columns */
  accountStoresByPk?: Maybe<AccountStores>;
  /** fetch data from the table: "accounts" */
  accounts: Array<Accounts>;
  /** fetch aggregated fields from the table: "accounts" */
  accountsAggregate: AccountsAggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  accountsByPk?: Maybe<Accounts>;
  /** An array relationship */
  executions: Array<Executions>;
  /** An aggregate relationship */
  executionsAggregate: ExecutionsAggregate;
  /** fetch data from the table: "executions" using primary key columns */
  executionsByPk?: Maybe<Executions>;
  /** fetch data from the table: "products" */
  products: Array<Products>;
  /** fetch data from the table: "products" using primary key columns */
  productsByPk?: Maybe<Products>;
  /** fetch data from the table: "sources" */
  sources: Array<Sources>;
  /** fetch aggregated fields from the table: "sources" */
  sourcesAggregate: SourcesAggregate;
  /** fetch data from the table: "sources" using primary key columns */
  sourcesByPk?: Maybe<Sources>;
  /** fetch data from the table: "stores" */
  stores: Array<Stores>;
  /** fetch aggregated fields from the table: "stores" */
  storesAggregate: StoresAggregate;
  /** fetch data from the table: "stores" using primary key columns */
  storesByPk?: Maybe<Stores>;
};


export type Query_RootAccountStoresArgs = {
  distinctOn?: InputMaybe<Array<AccountStoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountStoresOrderBy>>;
  where?: InputMaybe<AccountStoresBoolExp>;
};


export type Query_RootAccountStoresAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccountStoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountStoresOrderBy>>;
  where?: InputMaybe<AccountStoresBoolExp>;
};


export type Query_RootAccountStoresByPkArgs = {
  accountId: Scalars['uuid'];
  storeId: Scalars['uuid'];
};


export type Query_RootAccountsArgs = {
  distinctOn?: InputMaybe<Array<AccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
  where?: InputMaybe<AccountsBoolExp>;
};


export type Query_RootAccountsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
  where?: InputMaybe<AccountsBoolExp>;
};


export type Query_RootAccountsByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootExecutionsArgs = {
  distinctOn?: InputMaybe<Array<ExecutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExecutionsOrderBy>>;
  where?: InputMaybe<ExecutionsBoolExp>;
};


export type Query_RootExecutionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExecutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExecutionsOrderBy>>;
  where?: InputMaybe<ExecutionsBoolExp>;
};


export type Query_RootExecutionsByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProductsArgs = {
  distinctOn?: InputMaybe<Array<ProductsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductsOrderBy>>;
  where?: InputMaybe<ProductsBoolExp>;
};


export type Query_RootProductsByPkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSourcesArgs = {
  distinctOn?: InputMaybe<Array<SourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SourcesOrderBy>>;
  where?: InputMaybe<SourcesBoolExp>;
};


export type Query_RootSourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<SourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SourcesOrderBy>>;
  where?: InputMaybe<SourcesBoolExp>;
};


export type Query_RootSourcesByPkArgs = {
  source: Scalars['String'];
};


export type Query_RootStoresArgs = {
  distinctOn?: InputMaybe<Array<StoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StoresOrderBy>>;
  where?: InputMaybe<StoresBoolExp>;
};


export type Query_RootStoresAggregateArgs = {
  distinctOn?: InputMaybe<Array<StoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StoresOrderBy>>;
  where?: InputMaybe<StoresBoolExp>;
};


export type Query_RootStoresByPkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** An array relationship */
  accountStores: Array<AccountStores>;
  /** An aggregate relationship */
  accountStoresAggregate: AccountStoresAggregate;
  /** fetch data from the table: "accountStores" using primary key columns */
  accountStoresByPk?: Maybe<AccountStores>;
  /** fetch data from the table in a streaming manner: "accountStores" */
  accountStoresStream: Array<AccountStores>;
  /** fetch data from the table: "accounts" */
  accounts: Array<Accounts>;
  /** fetch aggregated fields from the table: "accounts" */
  accountsAggregate: AccountsAggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  accountsByPk?: Maybe<Accounts>;
  /** fetch data from the table in a streaming manner: "accounts" */
  accountsStream: Array<Accounts>;
  /** An array relationship */
  executions: Array<Executions>;
  /** An aggregate relationship */
  executionsAggregate: ExecutionsAggregate;
  /** fetch data from the table: "executions" using primary key columns */
  executionsByPk?: Maybe<Executions>;
  /** fetch data from the table in a streaming manner: "executions" */
  executionsStream: Array<Executions>;
  /** fetch data from the table: "products" */
  products: Array<Products>;
  /** fetch data from the table: "products" using primary key columns */
  productsByPk?: Maybe<Products>;
  /** fetch data from the table in a streaming manner: "products" */
  productsStream: Array<Products>;
  /** fetch data from the table: "sources" */
  sources: Array<Sources>;
  /** fetch aggregated fields from the table: "sources" */
  sourcesAggregate: SourcesAggregate;
  /** fetch data from the table: "sources" using primary key columns */
  sourcesByPk?: Maybe<Sources>;
  /** fetch data from the table in a streaming manner: "sources" */
  sourcesStream: Array<Sources>;
  /** fetch data from the table: "stores" */
  stores: Array<Stores>;
  /** fetch aggregated fields from the table: "stores" */
  storesAggregate: StoresAggregate;
  /** fetch data from the table: "stores" using primary key columns */
  storesByPk?: Maybe<Stores>;
  /** fetch data from the table in a streaming manner: "stores" */
  storesStream: Array<Stores>;
};


export type Subscription_RootAccountStoresArgs = {
  distinctOn?: InputMaybe<Array<AccountStoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountStoresOrderBy>>;
  where?: InputMaybe<AccountStoresBoolExp>;
};


export type Subscription_RootAccountStoresAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccountStoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountStoresOrderBy>>;
  where?: InputMaybe<AccountStoresBoolExp>;
};


export type Subscription_RootAccountStoresByPkArgs = {
  accountId: Scalars['uuid'];
  storeId: Scalars['uuid'];
};


export type Subscription_RootAccountStoresStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AccountStoresStreamCursorInput>>;
  where?: InputMaybe<AccountStoresBoolExp>;
};


export type Subscription_RootAccountsArgs = {
  distinctOn?: InputMaybe<Array<AccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
  where?: InputMaybe<AccountsBoolExp>;
};


export type Subscription_RootAccountsAggregateArgs = {
  distinctOn?: InputMaybe<Array<AccountsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
  where?: InputMaybe<AccountsBoolExp>;
};


export type Subscription_RootAccountsByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAccountsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<AccountsStreamCursorInput>>;
  where?: InputMaybe<AccountsBoolExp>;
};


export type Subscription_RootExecutionsArgs = {
  distinctOn?: InputMaybe<Array<ExecutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExecutionsOrderBy>>;
  where?: InputMaybe<ExecutionsBoolExp>;
};


export type Subscription_RootExecutionsAggregateArgs = {
  distinctOn?: InputMaybe<Array<ExecutionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExecutionsOrderBy>>;
  where?: InputMaybe<ExecutionsBoolExp>;
};


export type Subscription_RootExecutionsByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootExecutionsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ExecutionsStreamCursorInput>>;
  where?: InputMaybe<ExecutionsBoolExp>;
};


export type Subscription_RootProductsArgs = {
  distinctOn?: InputMaybe<Array<ProductsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProductsOrderBy>>;
  where?: InputMaybe<ProductsBoolExp>;
};


export type Subscription_RootProductsByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProductsStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<ProductsStreamCursorInput>>;
  where?: InputMaybe<ProductsBoolExp>;
};


export type Subscription_RootSourcesArgs = {
  distinctOn?: InputMaybe<Array<SourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SourcesOrderBy>>;
  where?: InputMaybe<SourcesBoolExp>;
};


export type Subscription_RootSourcesAggregateArgs = {
  distinctOn?: InputMaybe<Array<SourcesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SourcesOrderBy>>;
  where?: InputMaybe<SourcesBoolExp>;
};


export type Subscription_RootSourcesByPkArgs = {
  source: Scalars['String'];
};


export type Subscription_RootSourcesStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<SourcesStreamCursorInput>>;
  where?: InputMaybe<SourcesBoolExp>;
};


export type Subscription_RootStoresArgs = {
  distinctOn?: InputMaybe<Array<StoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StoresOrderBy>>;
  where?: InputMaybe<StoresBoolExp>;
};


export type Subscription_RootStoresAggregateArgs = {
  distinctOn?: InputMaybe<Array<StoresSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StoresOrderBy>>;
  where?: InputMaybe<StoresBoolExp>;
};


export type Subscription_RootStoresByPkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootStoresStreamArgs = {
  batchSize: Scalars['Int'];
  cursor: Array<InputMaybe<StoresStreamCursorInput>>;
  where?: InputMaybe<StoresBoolExp>;
};

export type DeleteAccountMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteAccountMutation = { __typename?: 'mutation_root', deleteAccountsByPk?: { __typename?: 'Accounts', id: any, email: string, password: string, accountStores: Array<{ __typename?: 'AccountStores', storeId: any, store?: { __typename?: 'Stores', id: any, name: string, externalStoreId: string, externalLocationId: string, enabled: boolean, schedule: string, source: SourcesEnum } | null }> } | null };

export type ExecuteMutationVariables = Exact<{
  storeId: Scalars['uuid'];
}>;


export type ExecuteMutation = { __typename?: 'mutation_root', execute?: { __typename?: 'ExecuteOutput', result: string } | null };

export type GetAccountQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetAccountQuery = { __typename?: 'query_root', accountsByPk?: { __typename?: 'Accounts', id: any, email: string, password: string, accountStores: Array<{ __typename?: 'AccountStores', storeId: any, store?: { __typename?: 'Stores', id: any, name: string, externalStoreId: string, externalLocationId: string, enabled: boolean, schedule: string, source: SourcesEnum } | null }> } | null };

export type GetAccountsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy> | AccountsOrderBy>;
}>;


export type GetAccountsQuery = { __typename?: 'query_root', accounts: Array<{ __typename?: 'Accounts', id: any, email: string, password: string, accountStores: Array<{ __typename?: 'AccountStores', storeId: any, store?: { __typename?: 'Stores', id: any, name: string, externalStoreId: string, externalLocationId: string, enabled: boolean, schedule: string, source: SourcesEnum } | null }> }>, accountsAggregate: { __typename?: 'AccountsAggregate', aggregate?: { __typename?: 'AccountsAggregateFields', count: number } | null } };

export type GetCurrentProductsByStoreIdQueryVariables = Exact<{
  storeId: Scalars['uuid'];
  date?: InputMaybe<Scalars['timestamptz']>;
}>;


export type GetCurrentProductsByStoreIdQuery = { __typename?: 'query_root', products: Array<{ __typename?: 'Products', id: any, externalId: string, storeId: any, name: string, brand?: string | null, description?: string | null, price: any, pricePerKg?: any | null, basisWeight?: any | null, discountPrice?: any | null, discountPricePerKg?: any | null, category: string, subCategory?: string | null, subSubCategory?: string | null, createdAt: any, updatedAt: any }> };

export type GetExecutionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExecutionsOrderBy> | ExecutionsOrderBy>;
}>;


export type GetExecutionsQuery = { __typename?: 'query_root', executions: Array<{ __typename?: 'Executions', id: any, date: any, duration?: number | null, error?: string | null, store?: { __typename?: 'Stores', id: any, name: string, source: SourcesEnum } | null }>, executionsAggregate: { __typename?: 'ExecutionsAggregate', aggregate?: { __typename?: 'ExecutionsAggregateFields', count: number } | null } };

export type GetSourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSourcesQuery = { __typename?: 'query_root', sources: Array<{ __typename?: 'Sources', source: string }> };

export type GetStoreQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetStoreQuery = { __typename?: 'query_root', storesByPk?: { __typename?: 'Stores', id: any, name: string, externalStoreId: string, externalLocationId: string, enabled: boolean, schedule: string, source: SourcesEnum } | null };

export type GetStoresQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StoresOrderBy> | StoresOrderBy>;
}>;


export type GetStoresQuery = { __typename?: 'query_root', stores: Array<{ __typename?: 'Stores', id: any, name: string, externalStoreId: string, externalLocationId: string, enabled: boolean, schedule: string, source: SourcesEnum, latestExecution: Array<{ __typename?: 'Executions', id: any, date: any, duration?: number | null, error?: string | null, store?: { __typename?: 'Stores', id: any, name: string, source: SourcesEnum } | null }> }>, storesAggregate: { __typename?: 'StoresAggregate', aggregate?: { __typename?: 'StoresAggregateFields', count: number } | null } };

export type InsertAccountMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  accountStores: Array<AccountStoresInsertInput> | AccountStoresInsertInput;
}>;


export type InsertAccountMutation = { __typename?: 'mutation_root', insertAccountsOne?: { __typename?: 'Accounts', id: any, email: string, password: string, accountStores: Array<{ __typename?: 'AccountStores', storeId: any, store?: { __typename?: 'Stores', id: any, name: string, externalStoreId: string, externalLocationId: string, enabled: boolean, schedule: string, source: SourcesEnum } | null }> } | null };

export type InsertStoreMutationVariables = Exact<{
  name: Scalars['String'];
  externalStoreId: Scalars['String'];
  externalLocationId: Scalars['String'];
  enabled: Scalars['Boolean'];
  schedule: Scalars['String'];
  source: SourcesEnum;
}>;


export type InsertStoreMutation = { __typename?: 'mutation_root', insertStoresOne?: { __typename?: 'Stores', id: any, name: string, externalStoreId: string, externalLocationId: string, enabled: boolean, schedule: string, source: SourcesEnum } | null };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'mutation_root', logout?: { __typename?: 'LogoutOutput', result: string } | null };

export type UpdateAccountMutationVariables = Exact<{
  id: Scalars['uuid'];
  email: Scalars['String'];
  password: Scalars['String'];
  accountStores: Array<AccountStoresInsertInput> | AccountStoresInsertInput;
}>;


export type UpdateAccountMutation = { __typename?: 'mutation_root', deleteAccountStores?: { __typename?: 'AccountStoresMutationResponse', affected_rows: number } | null, updateAccountsByPk?: { __typename?: 'Accounts', id: any, email: string, password: string, accountStores: Array<{ __typename?: 'AccountStores', storeId: any, store?: { __typename?: 'Stores', id: any, name: string, externalStoreId: string, externalLocationId: string, enabled: boolean, schedule: string, source: SourcesEnum } | null }> } | null, insertAccountStores?: { __typename?: 'AccountStoresMutationResponse', affected_rows: number } | null };

export type UpdateStoreMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
  externalStoreId: Scalars['String'];
  externalLocationId: Scalars['String'];
  enabled: Scalars['Boolean'];
  schedule: Scalars['String'];
  source: SourcesEnum;
}>;


export type UpdateStoreMutation = { __typename?: 'mutation_root', updateStoresByPk?: { __typename?: 'Stores', id: any, name: string, externalStoreId: string, externalLocationId: string, enabled: boolean, schedule: string, source: SourcesEnum } | null };

export type AccountFragment = { __typename?: 'Accounts', id: any, email: string, password: string, accountStores: Array<{ __typename?: 'AccountStores', storeId: any, store?: { __typename?: 'Stores', id: any, name: string, externalStoreId: string, externalLocationId: string, enabled: boolean, schedule: string, source: SourcesEnum } | null }> };

export type ExecutionFragment = { __typename?: 'Executions', id: any, date: any, duration?: number | null, error?: string | null, store?: { __typename?: 'Stores', id: any, name: string, source: SourcesEnum } | null };

export type ProductFragment = { __typename?: 'Products', id: any, externalId: string, storeId: any, name: string, brand?: string | null, description?: string | null, price: any, pricePerKg?: any | null, basisWeight?: any | null, discountPrice?: any | null, discountPricePerKg?: any | null, category: string, subCategory?: string | null, subSubCategory?: string | null, createdAt: any, updatedAt: any };

export type StoreFragment = { __typename?: 'Stores', id: any, name: string, externalStoreId: string, externalLocationId: string, enabled: boolean, schedule: string, source: SourcesEnum };

export const StoreFragmentDoc = gql`
    fragment store on Stores {
  id
  name
  externalStoreId
  externalLocationId
  enabled
  schedule
  source
}
    `;
export const AccountFragmentDoc = gql`
    fragment account on Accounts {
  id
  email
  password
  accountStores {
    storeId
    store {
      ...store
    }
  }
}
    ${StoreFragmentDoc}`;
export const ExecutionFragmentDoc = gql`
    fragment execution on Executions {
  id
  date
  duration
  store {
    id
    name
    source
  }
  error
}
    `;
export const ProductFragmentDoc = gql`
    fragment product on Products {
  id
  externalId
  storeId
  name
  brand
  description
  price
  pricePerKg
  basisWeight
  discountPrice
  discountPricePerKg
  category
  subCategory
  subSubCategory
  createdAt
  updatedAt
}
    `;
export const DeleteAccountDocument = gql`
    mutation DeleteAccount($id: uuid!) {
  deleteAccountsByPk(id: $id) {
    ...account
  }
}
    ${AccountFragmentDoc}`;

export function useDeleteAccountMutation() {
  return Urql.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument);
};
export const ExecuteDocument = gql`
    mutation Execute($storeId: uuid!) {
  execute(storeId: $storeId) {
    result
  }
}
    `;

export function useExecuteMutation() {
  return Urql.useMutation<ExecuteMutation, ExecuteMutationVariables>(ExecuteDocument);
};
export const GetAccountDocument = gql`
    query GetAccount($id: uuid!) {
  accountsByPk(id: $id) {
    ...account
  }
}
    ${AccountFragmentDoc}`;

export function useGetAccountQuery(options: Omit<Urql.UseQueryArgs<GetAccountQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAccountQuery, GetAccountQueryVariables>({ query: GetAccountDocument, ...options });
};
export const GetAccountsDocument = gql`
    query GetAccounts($limit: Int = 100, $offset: Int = 0, $orderBy: [AccountsOrderBy!] = [{id: ASC}]) {
  accounts(limit: $limit, offset: $offset, orderBy: $orderBy) {
    ...account
  }
  accountsAggregate {
    aggregate {
      count
    }
  }
}
    ${AccountFragmentDoc}`;

export function useGetAccountsQuery(options?: Omit<Urql.UseQueryArgs<GetAccountsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAccountsQuery, GetAccountsQueryVariables>({ query: GetAccountsDocument, ...options });
};
export const GetCurrentProductsByStoreIdDocument = gql`
    query GetCurrentProductsByStoreId($storeId: uuid!, $date: timestamptz = "now()") {
  products(
    where: {storeId: {_eq: $storeId}, updatedAt: {_lt: $date}}
    distinctOn: [externalId, category, subCategory, subSubCategory]
    orderBy: [{externalId: ASC}, {category: ASC}, {subCategory: ASC}, {subSubCategory: ASC}, {updatedAt: DESC}]
  ) {
    ...product
  }
}
    ${ProductFragmentDoc}`;

export function useGetCurrentProductsByStoreIdQuery(options: Omit<Urql.UseQueryArgs<GetCurrentProductsByStoreIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCurrentProductsByStoreIdQuery, GetCurrentProductsByStoreIdQueryVariables>({ query: GetCurrentProductsByStoreIdDocument, ...options });
};
export const GetExecutionsDocument = gql`
    query GetExecutions($limit: Int = 100, $offset: Int = 0, $orderBy: [ExecutionsOrderBy!] = [{id: ASC}]) {
  executions(limit: $limit, offset: $offset, orderBy: $orderBy) {
    ...execution
  }
  executionsAggregate {
    aggregate {
      count
    }
  }
}
    ${ExecutionFragmentDoc}`;

export function useGetExecutionsQuery(options?: Omit<Urql.UseQueryArgs<GetExecutionsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetExecutionsQuery, GetExecutionsQueryVariables>({ query: GetExecutionsDocument, ...options });
};
export const GetSourcesDocument = gql`
    query GetSources {
  sources {
    source
  }
}
    `;

export function useGetSourcesQuery(options?: Omit<Urql.UseQueryArgs<GetSourcesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSourcesQuery, GetSourcesQueryVariables>({ query: GetSourcesDocument, ...options });
};
export const GetStoreDocument = gql`
    query GetStore($id: uuid!) {
  storesByPk(id: $id) {
    ...store
  }
}
    ${StoreFragmentDoc}`;

export function useGetStoreQuery(options: Omit<Urql.UseQueryArgs<GetStoreQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStoreQuery, GetStoreQueryVariables>({ query: GetStoreDocument, ...options });
};
export const GetStoresDocument = gql`
    query GetStores($limit: Int = 100, $offset: Int = 0, $orderBy: [StoresOrderBy!] = [{id: ASC}]) {
  stores(limit: $limit, offset: $offset, orderBy: $orderBy) {
    ...store
    latestExecution: executions(limit: 1, orderBy: {date: DESC}) {
      ...execution
    }
  }
  storesAggregate {
    aggregate {
      count
    }
  }
}
    ${StoreFragmentDoc}
${ExecutionFragmentDoc}`;

export function useGetStoresQuery(options?: Omit<Urql.UseQueryArgs<GetStoresQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStoresQuery, GetStoresQueryVariables>({ query: GetStoresDocument, ...options });
};
export const InsertAccountDocument = gql`
    mutation InsertAccount($email: String!, $password: String!, $accountStores: [AccountStoresInsertInput!]!) {
  insertAccountsOne(
    object: {email: $email, password: $password, accountStores: {data: $accountStores}}
  ) {
    ...account
  }
}
    ${AccountFragmentDoc}`;

export function useInsertAccountMutation() {
  return Urql.useMutation<InsertAccountMutation, InsertAccountMutationVariables>(InsertAccountDocument);
};
export const InsertStoreDocument = gql`
    mutation InsertStore($name: String!, $externalStoreId: String!, $externalLocationId: String!, $enabled: Boolean!, $schedule: String!, $source: SourcesEnum!) {
  insertStoresOne(
    object: {name: $name, externalStoreId: $externalStoreId, enabled: $enabled, schedule: $schedule, externalLocationId: $externalLocationId, source: $source}
  ) {
    ...store
  }
}
    ${StoreFragmentDoc}`;

export function useInsertStoreMutation() {
  return Urql.useMutation<InsertStoreMutation, InsertStoreMutationVariables>(InsertStoreDocument);
};
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    result
  }
}
    `;

export function useLogoutMutation() {
  return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument);
};
export const UpdateAccountDocument = gql`
    mutation UpdateAccount($id: uuid!, $email: String!, $password: String!, $accountStores: [AccountStoresInsertInput!]!) {
  deleteAccountStores(where: {accountId: {_eq: $id}}) {
    affected_rows
  }
  updateAccountsByPk(
    pk_columns: {id: $id}
    _set: {email: $email, password: $password}
  ) {
    ...account
  }
  insertAccountStores(objects: $accountStores) {
    affected_rows
  }
}
    ${AccountFragmentDoc}`;

export function useUpdateAccountMutation() {
  return Urql.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument);
};
export const UpdateStoreDocument = gql`
    mutation UpdateStore($id: uuid!, $name: String!, $externalStoreId: String!, $externalLocationId: String!, $enabled: Boolean!, $schedule: String!, $source: SourcesEnum!) {
  updateStoresByPk(
    pk_columns: {id: $id}
    _set: {name: $name, externalStoreId: $externalStoreId, enabled: $enabled, schedule: $schedule, externalLocationId: $externalLocationId, source: $source}
  ) {
    ...store
  }
}
    ${StoreFragmentDoc}`;

export function useUpdateStoreMutation() {
  return Urql.useMutation<UpdateStoreMutation, UpdateStoreMutationVariables>(UpdateStoreDocument);
};