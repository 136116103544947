import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  accessToken: Scalars['String'];
};

export type RefreshTokenOutput = {
  __typename?: 'RefreshTokenOutput';
  accessToken: Scalars['String'];
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  login?: Maybe<LoginOutput>;
  refreshToken?: Maybe<RefreshTokenOutput>;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  furtkaToken: Scalars['String'];
};

export type Query_Root = {
  __typename?: 'query_root';
  /** There are no queries available to the current role. Either there are no sources or remote schemas configured, or the current role doesn't have the required permissions. */
  no_queries_available: Scalars['String'];
};

export type LoginMutationVariables = Exact<{
  furtkaToken: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'mutation_root', login?: { __typename?: 'LoginOutput', accessToken: string } | null };

export type RefreshTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshTokenMutation = { __typename?: 'mutation_root', refreshToken?: { __typename?: 'RefreshTokenOutput', accessToken: string } | null };


export const LoginDocument = gql`
    mutation Login($furtkaToken: String!) {
  login(furtkaToken: $furtkaToken) {
    accessToken
  }
}
    `;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
};
export const RefreshTokenDocument = gql`
    mutation RefreshToken {
  refreshToken {
    accessToken
  }
}
    `;

export function useRefreshTokenMutation() {
  return Urql.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument);
};