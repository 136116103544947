import { BrowserRouter as Router } from 'react-router-dom'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import { FC, PropsWithChildren } from 'react'

const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Router>
      <Menu />
      <main>{children}</main>
      <Footer />
    </Router>
  )
}

export default Layout
