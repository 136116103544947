import Meta from '../components/Meta'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  GetStoresDocument,
  GetStoresQuery,
  GetStoresQueryVariables,
  useExecuteMutation,
} from '../generated/urql.user'
import { useClient } from 'urql'
import { Alert, Button, ButtonGroup, Badge } from 'react-bootstrap'
import { PencilSquare, PersonPlus, Play } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router'
import { InfiniteTable } from '../components/InfiniteTable'

const BatchSize = 100

const Stores: FC = () => {
  // page content
  const pageTitle = 'Sklepy'
  const [stores, setStores] = useState<GetStoresQuery['stores']>([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const client = useClient()
  const navigate = useNavigate()
  const data = useMemo(() => stores, [stores])
  const [, execute] = useExecuteMutation()
  const [generalError, setGeneralError] = useState<string>()

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetStoresQuery, GetStoresQueryVariables>(
          GetStoresDocument,
          { offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setStores(refetch ? data.stores : stores.concat(data.stores))
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.storesAggregate.aggregate?.count || 0)
      }
    },
    [client, stores, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      {generalError && <Alert variant="danger">{generalError}</Alert>}
      <div className="mt-3 mb-3 d-flex justify-content-end">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/store')}
          >
            <PersonPlus size={20} />
            &nbsp;Dodaj
          </Button>
        </ButtonGroup>
      </div>
      <InfiniteTable<GetStoresQuery['stores'][number]>
        data={data}
        columns={[
          {
            Header: 'Id',
            accessor: ({ id }) => (
              <p className="mb-1 small text-secondary">{id}</p>
            ),
          },
          {
            Header: 'Źródło',
            accessor: ({ source }) => <Badge>{source}</Badge>,
          },
          { Header: 'Nazwa', accessor: 'name' },
          { Header: 'Zewnętrzny ID Sklepu', accessor: 'externalStoreId' },
          {
            Header: 'Zewnętrzny ID Lokalizacji',
            accessor: 'externalLocationId',
          },
          {
            Header: 'Harmonogram',
            accessor: ({ schedule, enabled }) => (
              <span>{enabled ? schedule : <Badge bg="danger">off</Badge>}</span>
            ),
          },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={() => navigate(`/store/${rowData.id}`)}
                  >
                    <PencilSquare size={18} />
                  </Button>
                  <Button
                    variant="success"
                    onClick={async () => {
                      setGeneralError(undefined)
                      try {
                        const { error } = await execute({ storeId: rowData.id })
                        if (error) {
                          setGeneralError(error.message)
                          return
                        }
                        navigate('/executions')
                      } catch (e: unknown) {
                        setGeneralError((e as Error).message)
                      }
                    }}
                  >
                    <Play size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
    </div>
  )
}

export default Stores
