import Meta from '../components/Meta'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  GetExecutionsDocument,
  GetExecutionsQuery,
  GetExecutionsQueryVariables,
  OrderBy,
} from '../generated/urql.user'
import { useClient } from 'urql'
import { InfiniteTable } from '../components/InfiniteTable'
import { Badge } from 'react-bootstrap'
import moment from 'moment/moment'

const BatchSize = 100

const Executions: FC = () => {
  // page content
  const pageTitle = 'Historia wykonań'
  const [executions, setExecutions] = useState<
    GetExecutionsQuery['executions']
  >([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const client = useClient()
  const data = useMemo(() => executions, [executions])

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetExecutionsQuery, GetExecutionsQueryVariables>(
          GetExecutionsDocument,
          {
            offset: refetch ? 0 : offset,
            limit: BatchSize,
            orderBy: [{ date: OrderBy.Desc }],
          },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setExecutions(
          refetch ? data.executions : executions.concat(data.executions)
        )
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.executionsAggregate.aggregate?.count || 0)
      }
    },
    [client, executions, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [])

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-4" />
      <InfiniteTable<GetExecutionsQuery['executions'][number]>
        data={data}
        columns={[
          {
            Header: 'Źródło',
            accessor: ({ store }) => (
              <Badge bg="primary">{store?.source}</Badge>
            ),
          },
          {
            Header: 'Data / Id',
            accessor: ({ id, date }) => (
              <>
                <p className="mb-1">
                  {moment(date).format('DD/MM/yyyy HH:mm:ss')}
                </p>
                <p className="mb-1 small text-secondary">{id}</p>
              </>
            ),
          },
          {
            Header: 'Czas trwania',
            accessor: ({ duration }) => (
              <span>
                {typeof duration === 'number' ? (
                  `${Math.round(duration / 1000)}s`
                ) : (
                  <Badge bg="warning">W trakcie</Badge>
                )}
              </span>
            ),
          },
          {
            Header: 'Sklep',
            accessor: ({ store }) => (
              <a href={`/store/${store?.id}`}>{store?.name}</a>
            ),
          },
          {
            Header: 'Błąd',
            accessor: ({ error, duration }) => {
              if (error) {
                const lines = error.split('\n')
                return (
                  <>
                    <p className="mb-1">
                      <Badge bg="danger">{lines[0]}</Badge>
                    </p>
                    {lines.length > 1 && (
                      <p className="mb-1 small text-secondary">
                        {lines.slice(1).map((line) => (
                          <>
                            {line}
                            <br />
                          </>
                        ))}
                      </p>
                    )}
                  </>
                )
              } else if (typeof duration === 'number') {
                return <Badge bg="success">OK</Badge>
              }
            },
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
    </div>
  )
}

export default Executions
