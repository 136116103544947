import Meta from '../components/Meta'
import { FC, useCallback, useEffect, useState } from 'react'
import {
  GetCurrentProductsByStoreIdDocument,
  GetCurrentProductsByStoreIdQuery,
  GetCurrentProductsByStoreIdQueryVariables,
  GetStoresDocument,
  GetStoresQuery,
  GetStoresQueryVariables,
} from '../generated/urql.user'
import { useClient } from 'urql'
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Col,
  Form,
  Row,
} from 'react-bootstrap'
import { CloudDownload } from 'react-bootstrap-icons'
import moment from 'moment'
import { download } from '../lib/download'
import { InfiniteTable } from '../components/InfiniteTable'
import ExcelJS from 'exceljs'

const BatchSize = 100

const Reports: FC = () => {
  // page content
  const pageTitle = 'Raporty'
  const [stores, setStores] = useState<GetStoresQuery['stores']>([])
  const [count, setCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [date, setDate] = useState<Date>(new Date())
  const client = useClient()
  const [generalError, setGeneralError] = useState<string>()

  const createReport = useCallback(
    async (storeId: string): Promise<any> => {
      const { data, error } = await client
        .query<
          GetCurrentProductsByStoreIdQuery,
          GetCurrentProductsByStoreIdQueryVariables
        >(GetCurrentProductsByStoreIdDocument, {
          storeId,
          date,
        })
        .toPromise()

      if (error) {
        setGeneralError(error.message)
        return
      }

      if (!data?.products) {
        return
      }

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Produkty')
      worksheet.views = [
        {
          state: 'frozen',
          xSplit: 0,
          ySplit: 1,
          topLeftCell: 'A2',
          activeCell: 'A1',
        },
      ]
      worksheet.columns = [
        {
          header: 'ID',
          key: 'externalId',
          width: 16,
        },
        {
          header: 'Nazwa',
          key: 'name',
          width: 60,
        },
        {
          header: 'Marka',
          key: 'brand',
          width: 50,
        },
        {
          header: 'Opis',
          key: 'description',
          width: 60,
        },
        {
          header: 'Cena',
          key: 'price',
          width: 20,
        },
        {
          header: 'Cena za kg',
          key: 'pricePerKg',
          width: 20,
        },
        {
          header: 'Cena promocyjna',
          key: 'discountPrice',
          width: 20,
        },
        {
          header: 'Cena promocyjna za kg',
          key: 'discountPricePerKg',
          width: 20,
        },
        {
          header: 'Waga bazowa',
          key: 'basisWeight',
          width: 20,
        },
        {
          header: 'Kategoria',
          key: 'category',
          width: 30,
        },
        {
          header: 'Podkategoria',
          key: 'subCategory',
          width: 30,
        },
        {
          header: 'Podpodkategoria',
          key: 'subSubCategory',
          width: 30,
        },
        {
          header: 'Data utworzenia',
          key: 'createdAt',
          width: 22,
        },
        {
          header: 'Data modyfikacji',
          key: 'updatedAt',
          width: 22,
        },
      ]
      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { size: 14, color: { argb: '00FFFFFF' }, bold: true }
        cell.style.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF000000' },
        }
      })

      const products = data.products.sort(
        (p1, p2) =>
          p1.category.localeCompare(p2.category) ||
          p1.subCategory?.localeCompare(p2.subCategory || '') ||
          p1.subSubCategory?.localeCompare(p2.subSubCategory || '') ||
          p1.name.localeCompare(p2.name)
      )

      for (const product of products) {
        const row = worksheet.addRow({
          ...product,
          createdAt: moment(product.createdAt).format('DD/MM/yyyy HH:mm:ss'),
          updatedAt: moment(product.updatedAt).format('DD/MM/yyyy HH:mm:ss'),
        })
        row.eachCell((cell, i) => {
          cell.alignment = { wrapText: true, vertical: 'top' }
          cell.font = { size: 14, bold: i === 2 }
          if (i === 1) {
            cell.numFmt = '0'
          }
          if ([5, 6].includes(i)) {
            cell.numFmt = '#,##0.00 zł'
          }
          if ([10, 11].includes(i)) {
            cell.numFmt = 'DD/MM/yyyy\\ HH:mm:ss'
            cell.alignment.horizontal = 'right'
          }
        })
      }

      return workbook.xlsx.writeBuffer()
    },
    [client, date]
  )

  const loadMoreRows = useCallback(
    async ({ refetch = false }: { refetch: boolean } = { refetch: false }) => {
      const { data, error } = await client
        .query<GetStoresQuery, GetStoresQueryVariables>(
          GetStoresDocument,
          { offset: refetch ? 0 : offset, limit: BatchSize },
          { requestPolicy: refetch ? 'network-only' : undefined }
        )
        .toPromise()

      if (error) {
        console.error(error)
        return
      }

      if (data) {
        setStores(refetch ? data.stores : stores.concat(data.stores))
        setOffset(refetch ? BatchSize : offset + BatchSize)
        setCount(data.storesAggregate.aggregate?.count || 0)
      }
    },
    [client, stores, offset]
  )

  useEffect(() => {
    loadMoreRows({ refetch: true }).then(() => {})
  }, [date])

  return (
    <div>
      <Meta title={pageTitle} />
      <Row style={{ width: '50%', marginTop: '32px', marginBottom: '32px' }}>
        <Col>
          <small style={{ color: '#666' }}>Data:</small>
          <Form.Control
            type="date"
            value={moment(date).format('yyyy-MM-DD')}
            onChange={(event) =>
              setDate(moment(event.target.value).startOf('day').toDate())
            }
          />
        </Col>
      </Row>
      {generalError && <Alert variant="danger">{generalError}</Alert>}
      <InfiniteTable<GetStoresQuery['stores'][number]>
        data={stores}
        columns={[
          {
            Header: 'Nazwa / Id sklepu',
            accessor: ({ id, name }) => (
              <>
                <p className="mb-1">{name}</p>
                <p className="mb-1 small text-secondary">{id}</p>
              </>
            ),
          },
          {
            Header: 'Ostatnia aktualizacja',
            accessor: ({ latestExecution }) => {
              const execution = latestExecution[0]

              if (!execution) {
                return <span>Nigdy</span>
              }

              if (execution.error) {
                return (
                  <>
                    <p className="mb-1">
                      <Badge bg="danger">Błąd</Badge>
                    </p>
                    <p className="mb-1 small text-secondary">
                      {moment(execution.date).format('DD/MM/yyyy HH:mm:ss')}
                    </p>
                  </>
                )
              }

              if (execution.duration === null) {
                return <Badge bg="warning">W trakcie</Badge>
              }

              return (
                <>
                  <p className="mb-1">
                    <Badge bg="success">OK</Badge>{' '}
                  </p>
                  <p className="mb-1 small text-secondary">
                    {moment(execution.date).format('DD/MM/yyyy HH:mm:ss')}
                  </p>
                </>
              )
            },
          },
          {
            Header: 'Źródło',
            accessor: ({ source }) => <Badge bg="primary">{source}</Badge>,
          },
          { Header: 'Źródłowy ID', accessor: 'externalStoreId' },
          {
            Header: '',
            id: 'actions',
            accessor: (rowData) => (
              <div className="d-flex justify-content-end">
                <ButtonGroup>
                  <Button
                    variant="primary"
                    onClick={async () => {
                      const data = await createReport(rowData.id)
                      if (data) {
                        download({
                          filename: `Raport dla sklepu ${rowData.name} ${moment(
                            date
                          ).format('yyyy-MM-DD')}.xlsx`,
                          mimeType:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          data,
                        })
                      }
                    }}
                  >
                    <CloudDownload size={18} />
                  </Button>
                </ButtonGroup>
              </div>
            ),
          },
        ]}
        loadMoreRows={loadMoreRows}
        hasMore={offset < count}
      />
    </div>
  )
}

export default Reports
