import decode from 'jwt-decode'
import { createContext, useCallback, useState } from 'react'
import { useCookies } from 'react-cookie'

export type Auth = ReturnType<typeof useAuth>
export const AuthContext = createContext<Auth>({
  accessToken: null,
  userId: '',
  initials: '',
  name: undefined,
  setAccessToken: async () => {},
  clearTokens: async () => {},
  isLogged: false,
})

type AccessToken = {
  sub: string
  exp: string
  'https://hasura.io/jwt/claims': {
    'x-hasura-user-id': string
    'x-hasura-user-name': string
  }
}

export function useAuth() {
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [, , removeRefreshToken] = useCookies(['everliFrontendRefreshToken'])

  return {
    accessToken,
    get userId() {
      if (this.accessToken) {
        const payload = decode<AccessToken>(this.accessToken)
        return payload['https://hasura.io/jwt/claims']['x-hasura-user-id']
      }

      return '?'
    },
    get initials() {
      if (this.name) {
        return this.name
          .split(' ')
          .map((part) => part[0])
          .join('')
      }

      return '?'
    },
    get name() {
      if (this.accessToken) {
        const payload = decode<AccessToken>(this.accessToken)
        return payload['https://hasura.io/jwt/claims']['x-hasura-user-name']
      }

      return undefined
    },
    setAccessToken,
    clearTokens: useCallback(async () => {
      setAccessToken(null)
      removeRefreshToken('everliFrontendRefreshToken')
    }, [setAccessToken, removeRefreshToken]),
    isLogged: !!accessToken,
  }
}

export function isTokenExpired(accessToken: string, offset = 0) {
  const payload = decode<AccessToken>(accessToken)
  return Date.now() >= parseInt(payload.exp) * 1000 + offset
}
