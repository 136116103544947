import Meta from '../components/Meta'
import { FC } from 'react'
import LoginButton from '../components/LoginButton'

const Login: FC = () => {
  // page content
  const pageTitle = 'Logowanie'

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="d-flex my-5 justify-content-center">
        <LoginButton />
      </div>
    </div>
  )
}

export default Login
