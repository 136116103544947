import { Container } from 'react-bootstrap'

const Footer = () => {
  return (
    <Container>
      <footer className="text-center border-top mt-5 fs-6">
        &copy; Sygnalite 2023
      </footer>
    </Container>
  )
}

export default Footer
