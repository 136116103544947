import Meta from '../components/Meta'
import { FC, useEffect, useState } from 'react'
import { useClient } from 'urql'
import { useParams } from 'react-router'
import { Controller, useForm } from 'react-hook-form'
import 'react-js-cron/dist/styles.css'
import {
  GetStoreDocument,
  GetStoreQuery,
  GetStoreQueryVariables,
  SourcesEnum,
  useGetSourcesQuery,
  useInsertStoreMutation,
  useUpdateStoreMutation,
} from '../generated/urql.user'
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  FloatingLabel,
  Form,
  Row,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import { ChevronLeft } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import Cron from 'react-js-cron'

type FormStore = {
  name: string
  externalStoreId: string
  externalLocationId: string
  enabled: boolean
  schedule: string
  source: SourcesEnum
}

const Store: FC = () => {
  const pageTitle = 'Sklep'
  const { id } = useParams()
  const client = useClient()
  const navigate = useNavigate()
  const [, setGetStoreFetching] = useState(false)
  const [{ fetching: updateStoreFetching }, updateStore] =
    useUpdateStoreMutation()
  const [{ fetching: insertStoreFetching }, insertStore] =
    useInsertStoreMutation()
  const [generalError, setGeneralError] = useState<string>()
  const [successToast, setSuccessToast] = useState(false)
  const [{ data: getSourcesData }] = useGetSourcesQuery()
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,
    control,
    watch,
  } = useForm<FormStore>({
    defaultValues: {
      name: '',
      externalStoreId: '',
      externalLocationId: '',
      enabled: true,
      schedule: '0 0 * * *',
      source: SourcesEnum.Everli,
    },
  })

  useEffect(() => {
    if (id) {
      setGetStoreFetching(true)
      client
        .query<GetStoreQuery, GetStoreQueryVariables>(GetStoreDocument, {
          id,
        })
        .toPromise()
        .then(({ data }) => {
          if (data?.storesByPk) {
            reset({
              name: data.storesByPk.name || '',
              externalStoreId: data.storesByPk.externalStoreId || '',
              externalLocationId: data.storesByPk.externalLocationId || '',
              enabled: data.storesByPk.enabled,
              schedule: data.storesByPk.schedule || '0 0 * * *',
              source: data.storesByPk.source,
            })
          }
        })
        .finally(() => setGetStoreFetching(false))
    }
  }, [id, client, reset])

  async function doSaveStore(store: FormStore) {
    setGeneralError(undefined)

    const { error, data } = id
      ? await updateStore({
          ...store,
          id,
        })
      : await insertStore(store)

    if (error) {
      setGeneralError(error.message)
      return
    }

    setSuccessToast(true)

    if (data && 'insertStoresOne' in data) {
      navigate(`/store/${data.insertStoresOne?.id}`)
    }
  }

  return (
    <div>
      <Meta title={pageTitle} />
      <div className="mt-3 mb-3 d-flex justify-content-start">
        <ButtonGroup>
          <Button
            className="d-flex align-items-center"
            onClick={() => navigate('/')}
          >
            <ChevronLeft size={20} />
            &nbsp;Wróć
          </Button>
        </ButtonGroup>
      </div>
      <Form onSubmit={handleSubmit(doSaveStore, console.log)} className="mt-4">
        {generalError && <Alert variant="danger">{generalError}</Alert>}

        <ToastContainer
          position="bottom-center"
          className="mb-4"
          style={{ zIndex: 10 }}
        >
          <Toast
            onClose={() => setSuccessToast(false)}
            show={successToast}
            autohide
            animation={true}
            bg="success"
          >
            <Toast.Header>
              <strong className="me-auto">Sukces</strong>
            </Toast.Header>
            <Toast.Body>Pomyślnie zapisano parametry sklepu</Toast.Body>
          </Toast>
        </ToastContainer>

        <Row>
          <Col>
            <h4>Dane sklepu</h4>

            <Controller
              name="source"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Form.Group className="mb-3">
                  <Form.Label className="small text-secondary">
                    Rodzaj
                  </Form.Label>
                  <Form.Select value={value} onChange={onChange}>
                    {getSourcesData?.sources.map(({ source }) => (
                      <option key={source} value={source.toUpperCase()}>
                        {source.toUpperCase()}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}
            />

            <FloatingLabel label="Nazwa" className="mb-3">
              <Form.Control
                isInvalid={!!errors.name}
                type="text"
                placeholder="Nazwa"
                {...register('name', {
                  required: 'Proszę podać nazwę sklepu',
                })}
              />
              {errors.name && (
                <Form.Control.Feedback>
                  {errors.name.message}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>

            {watch('source') !== SourcesEnum.Biedronka && (
              <FloatingLabel
                label="Zewnętrzny identyfikator sklepu"
                className="mb-3"
              >
                <Form.Control
                  isInvalid={!!errors.externalStoreId}
                  type="text"
                  placeholder="Zewnętrzny identyfikator"
                  {...register('externalStoreId', {
                    required: 'Proszę podać zewnętrzny identyfikator sklepu',
                  })}
                />
                {errors.externalStoreId && (
                  <Form.Control.Feedback>
                    {errors.externalStoreId.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            )}

            {watch('source') !== SourcesEnum.Biedronka && (
              <FloatingLabel label="Zewnętrzny ID Lokalizacji" className="mb-3">
                <Form.Control
                  isInvalid={!!errors.externalLocationId}
                  type="text"
                  placeholder="Zewnętrzny ID Lokalizacji"
                  {...register('externalLocationId', {
                    required: 'Proszę podać zewnętrzny ID lokalizacji',
                  })}
                />
                {errors.externalLocationId && (
                  <Form.Control.Feedback>
                    {errors.externalLocationId.message}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            )}

            <Form.Check
              type="switch"
              label="Aktywne"
              className="mb-3"
              {...register('enabled')}
            />
            {errors.enabled && (
              <Form.Control.Feedback>
                {errors.enabled.message}
              </Form.Control.Feedback>
            )}

            <h4>Harmonogram</h4>
            <div className="mb-6">
              <Controller
                name="schedule"
                control={control}
                rules={{
                  required: 'Proszę uzupełnić harmonogram',
                }}
                render={({ field: { value, onChange } }) => {
                  return <Cron value={value} setValue={onChange} />
                }}
              />
              {errors.schedule && (
                <Form.Control.Feedback>
                  {errors.schedule.message}
                </Form.Control.Feedback>
              )}
            </div>
          </Col>
        </Row>
        <Button
          variant="primary"
          type="submit"
          disabled={updateStoreFetching || insertStoreFetching}
        >
          Zapisz
        </Button>
      </Form>
    </div>
  )
}

export default Store
